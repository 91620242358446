body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #1f2933;
  background-color: #f5f7fa;
  font-size: 16px;
}

a {
  font-weight: 700;
  text-decoration: underline;
  color: #1f2933;
}

.CodeMirror {
  padding: 10px 0;
  border-radius: 6px;
  height: auto;
}

.pickr .pcr-button {
  height: 34px;
  width: 34px;
}

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  /* Added */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 5;
}

[data-reach-dialog-content] {
  outline: none;
}
